<template>
  <div align="center" class='holter-form'>
    <div id="DomPdf" style="width:550px;height:auto;">

      <div>
        <span style="display: inline-block; font-size: 24px">
            老年人能力评估
        </span>
        <img style="width: 100px; vertical-align: middle; display: inline-block"
            src="../../../../public/img/logo_xingou.png" />
      </div>
      <div>
          姓名：{{ info.realName}};     时间：{{ today }}
      </div>
      <el-row align="left" style="border:solid 2px;">
          <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">内容明细
          </div>
          
          <el-tabs v-model="activeName" tab-position="top" align="left" style="height: auto">
              <el-tab-pane name="自理能力" label="自理能力"><table_b1 ></table_b1></el-tab-pane>
              <el-tab-pane name="基础运动" label="基础运动"><table_b2></table_b2></el-tab-pane>
              <el-tab-pane name="精神状态" label="精神状态"><table_b3></table_b3></el-tab-pane>
              <el-tab-pane name="感知觉与社会参与" label="感知觉与社会参与"><table_b4></table_b4></el-tab-pane>
               
              <el-tab-pane name="综合" label="综合"><table_c></table_c></el-tab-pane>
          </el-tabs>
      </el-row>


      <div style="text-align:right;font-size:14px">*提示:本报告参考国家标准《老年人能力评估规范2023》</div>
      <br>
    
    </div>
    <el-button v-show="showButton" type="primary" plain @click="uploadReport()">上传报告</el-button>
    <el-button type="primary" plain @click="downloadReport()">下载报告</el-button>
  </div>
</template>
<script>
  import table_b1 from "@/views/older_station/evaluation_report/B1";
  import table_b2 from "@/views/older_station/evaluation_report/B2";
  import table_b3 from "@/views/older_station/evaluation_report/B3";
  import table_b4 from "@/views/older_station/evaluation_report/B4";
  import table_c from "@/views/older_station/evaluation_report/C";
  import jsPDF from 'jspdf';
  import html2canvas from 'html2canvas';
  import Vue from 'vue';
  Vue.prototype.$bus = new Vue();
  export default {
    components: {
    table_b1,
    table_b2,
    table_b3,
    table_b4,
    table_c,
   
  },
  props: {
        userId: String,  // 【其他平台】读取的属性
        reportStatus: String
    },
    data() {
      return {
        today: "2024-09-01",
        activeName: "自理能力",
        uid: this.userId,
        // reportStatus: this.reportStatus,
        info:{
                realName: ' ',
                age: ' ',
                gender: ' ',
                height: ' ',
                weight: ' ',
            },
       

      };
    },
 
  computed: {
      selfCareScore: {
        get() { return this.$store.state.home_older.selfCareScore },
        set(val) { this.$store.commit('home_older/updateSelfCareScore', val) }
      },
      basicMobilityScore:  {
        get() { return this.$store.state.home_older.basicMobilityScore },
        set(val) { this.$store.commit('home_older/updateBasicMobilityScore', val) }
      },
      mentalStateScore: {
        get() { return this.$store.state.home_older.mentalStateScore },
        set(val) { this.$store.commit('home_older/updateMentalStateScore', val) }
      },
      sensorySocialScore: {
        get() { return this.$store.state.home_older.sensorySocialScore },
        set(val) { this.$store.commit('home_older/updateSensorySocialScore', val) }
      },
      coma: {
        get() { return this.$store.state.home_older.coma },
        set(val) { this.$store.commit('home_older/updateComa', val) }
      },
      dementia: {
        get() { return this.$store.state.home_older.dementia },
        set(val) { this.$store.commit('home_older/updateDementia', val) }
      },
      riskEvents: {
        get() { return this.$store.state.home_older.riskEvents },
        set(val) { this.$store.commit('home_older/updateRiskEvents', val) }
      },
      // reportStatus: {
      //   get() { return this.$store.state.home_older.reportStatus },
      //   set(val) { this.$store.commit('home_older/updateReportStatus', val) }
      // },
      showButton() {
            if(this.reportStatus === '1') return false
            else return true
          
    },
    report() {
      return {selfCareScore: this.selfCareScore, basicMobilityScore: this.basicMobilityScore,
      mentalStateScore: this.mentalStateScore,
      sensorySocialScore: this.sensorySocialScore,
      coma: this.coma,
      dementia: this.dementia,
      riskEvents: this.riskEvents
    }}
  },
  
 methods: {
   TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + MM + '-' + d
        },
    getToday() {
            var today = new Date();
           
            this.today = this.TimeProcess(today)
           
            console.log(this.today)

        },
    
    
    uploadReport() {
      
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
        this.$http.adornHeader(this.header)
        this.$http({
            url: this.$http.adornUrl('/personal/olderevaluation/app/upload'),
            method: 'post',
            data: this.$http.adornData({ uid: this.uid, report: JSON.stringify(this.report)})
        }).then(({ data }) => {
            if (data && data.code === 0) {
                 this.$message({
                    message: "上传成功！",
                    type: "success",
                  }); 

            } else {

                this.$message.error(data.msg)
            }
        })
    },

  queryReport() {
          
      this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      this.$http.adornHeader(this.header)
      this.$http({
          url: this.$http.adornUrl('/personal/olderevaluation/app/query'),
          method: 'get',
          params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
          if (data && data.code === 0) {
              console.log(data.report)
              let report = JSON.parse(data.report.reportContent) 
              console.log("sbbbtyy")
              console.log(report)
              this.selfCareScore = report.selfCareScore
              this.basicMobilityScore = report.basicMobilityScore
              this.mentalStateScore = report.mentalStateScore
              this.sensorySocialScore = report.sensorySocialScore
              this.coma = report.coma
              this.dementia = report.dementia
              this.riskEvents = report.riskEvents
              this.$bus.$emit('updateSelfCareScore', this.selfCareScore);
              this.$bus.$emit('updateBasicMobilityScore', this.basicMobilityScore);
              this.$bus.$emit('updateMentalStateScore', this.mentalStateScore);
              this.$bus.$emit('updatesSensorySocialScore', this.sensorySocialScore);
          } else {

              this.$message.error(data.msg)
          }
      })
          
         
      },


    getPersonalInfo() {
          
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
        this.$http.adornHeader(this.header)
        this.$http({
            url: this.$http.adornUrl('/personal/info/app/getPersonalInfo'),
            method: 'get',
            params: this.$http.adornParams({ uid: this.uid })
        }).then(({ data }) => {
            if (data && data.code === 0) {
                this.info.realName = data.info.realName
                this.info.age = data.info.age
                var genderMap = { 0: "女", 1: "男" }
                this.info.gender = genderMap[data.info.gender]                
            } else {

                this.$message.error("未填写")
            }
        })
    },

    async  downloadReport() {
        const pdf = new jsPDF('p', 'mm', 'a4')
        // 获取所有分页的内容
        console.log("ssssssffff")
        
        const label_list = ["自理能力", "基础运动", "精神状态", "感知觉与社会参与", "综合"]
        
        // 循环遍历每一页的内容
        let height =0
        for (let i = 0; i < 5; i++) {
          // const page = pages[i];
          this.activeName = label_list[i]
          await this.sleep(500);
          // 将每一页的内容添加到pdf中
          const element = document.querySelector('#DomPdf');
          const canvas = await html2canvas(element);
          const imgData = canvas.toDataURL('image/gif');
          var ctx = canvas.getContext('2d');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          var a4w = 190; var a4h = 287; // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
          var imgHeight = Math.floor(a4h * canvas.width / a4w); // 按A4显示比例换算一页图像的像素高度
          var renderedHeight = 0;
          while (renderedHeight < canvas.height) {
              var page = document.createElement('canvas');
              page.width = canvas.width;
              page.height = Math.min(imgHeight, canvas.height - renderedHeight);// 可能内容不足一页            
            // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
              page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
              pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width)); // 添加图像到页面，保留10mm边距
              
              renderedHeight += imgHeight;
              if (renderedHeight < canvas.height) {
                  pdf.addPage();// 如果后面还有内容，添加一个空页
              }
          }

          if (i < 4) {
            pdf.addPage();
          }
          // pdf.addImage(imgData, 'gif', 0, 0, pdfWidth, pdfHeight);
          // height += pdfHeight;
        }
        pdf.save( this.info.realName + '.pdf');
      },

      sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
    }

 },

    //生命周期 - 创建完成（可以访问当前this实例）
  created() {
      console.log("sbshab")
      console.log(this.$route.query.uid)
      
  },
 mounted() {
        // this.initCanvas()
        if (this.$route.query.uid) {
              console.log("看看是否传参UID：");
              console.log(this.$route.query.uid);
              this.uid = this.$route.query.uid
        }
        if (this.$route.query.reportStatus) {
              console.log("看看是否传参：");
              console.log(this.$route.query.reportStatus);
              this.reportStatus = 0
        }

        this.getToday()
        this.getPersonalInfo()
        this.queryReport()
        // window.setTimeout(function () {
        //     window.location.reload();
        // },1000)
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { 
      this.selfCareScore = [4,4,4,4,4,4,4,4]
      this.basicMobilityScore = [4, 4, 4, 3]
      this.mentalStateScore = [4,4,4,4,4,4,1,1,2] 
      this.sensorySocialScore = [2,2,4,3,4]
      this.coma = false
      this.dementia = false
      this.riskEvents = false

    }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发

  };




</script>