<template>
  <div>
    <!-- <h2>自理能力</h2> -->
    
    <div v-for="(question, index) in selfCareScoreQuestions" :key="index + 'B.1'" class="question-section">
      <h3>{{ question.title }}</h3>
      <div v-for="(option, idx) in question.options" :key="idx + 'B1'" class="option">
        <label>
          <input 
            type="radio" 
            :name="'B1question' + index" 
            :value="option.score" 
            v-model="question.selectedScore"
            :checked="ifchecked(question.selectedScore, option.score)"
          >
          {{ option.text}}
        </label>
      </div>
    </div>
    
    <div class="total-score">
      <h3>总计得分: {{ totalScore }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selfCareScoreQuestions: [
        {
          title: "B.1.1 进食：使用适当的器具将食物送入口中并咽下",
          selectedScore: 4,
          options: [
            { text: "独立使用器具将食物送进口中并咽下，没有呛咳", score: 4 },
            { text: "在他人指导或提示下完成，或独立使用辅具，没有呛咳", score: 3 },
            { text: "进食中需要少量接触式协助，偶尔（每月一次及以上）呛咳", score: 2 },
            { text: "在进食中需要大量接触式协助，经常（每周一次及以上）呛咳", score: 1 },
            { text: "完全依赖他人协助进食，或吞咽困难，或留置营养管", score: 0 },
          ],
        },
        {
          title: "B.1.2 修饰：指洗脸、刷牙、梳头、刮脸、剪指（趾）甲等",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
        {
          title: "B.1.3 洗澡：清洗和擦干身体",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
        {
          title: "B.1.4 穿/脱上衣：指穿/脱上身衣服、系扣、拉拉链等",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
        {
          title: "B.1.5 穿/脱裤子和鞋袜：指穿/脱裤子、鞋袜等",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
        {
          title: "B.1.6 小便控制：控制和排出尿液的能力",
          selectedScore: 4,
          options: [
            { text: "可自行控制排尿，排尿次数、排尿控制均正常", score: 4 },
            { text: "白天可自行控制排尿次数，夜间出现排尿次数增多、排尿控制较差，或自行使用尿布、尿垫等辅助用物", score: 3 },
            { text: "白天大部分时间可自行控制排尿、偶出现（每天＜1次，但每周＞1次）尿失禁，夜间控制排尿较差，或他人少量协助使用尿布、尿垫等辅助用物", score: 2 },
            { text: "白天大部分时间不能控制排尿（每天≥1次，但尚未完全失控）夜间出现尿失禁，或他人大量协助使用尿布、尿垫等辅助用物", score: 1 },
            { text: "小便失禁，完全不能控制排尿，或留置导尿管", score: 0 },
          ],
        },
        {
          title: "B.1.7 大便控制：控制和排出粪便的能力",
          selectedScore: 4,
          options: [
            { text: "可正常自行控制大便排出", score: 4 },
            { text: "有时出现（每周＜1次）便秘或大便失禁，或自行使用开塞露、尿垫等辅助用物", score: 3 },
            { text: "经常出现（每天＜1次，但每周＞1次）便秘或大便失禁，或他人少量协助开塞露、尿垫等辅助用物", score: 2 },
            { text: "大部分时间均出现（每天≥1次）便秘或大便失禁，但尚非完全失控，或他人大量协助使用开塞露、尿垫等辅助用物", score: 1 },
            { text: "严重便秘或者完全大便失禁，需要依赖他人协助排便或清洁皮肤", score: 0 },
          ],
        },
        {
          title: "B.1.8 如厕：上厕所排泄大小便，并清洁身体（评估中强调排泄前解开裤子、完成排泄后清洁身体、穿上裤子）",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
      ],
    };
  },
  created() {

    this.$bus.$on('updateSelfCareScore', (score) => {
      let N = score.length
      for (let i = 0 ; i< N; i++) {
          this.selfCareScoreQuestions[i].selectedScore = score[i]
      }
    });
    // let N = this.selfCareScore.length
    // for (let i = 0 ; i< N; i++) {
    //     this.selfCareScoreQuestions[i].selectedScore = this.selfCareScore[i]
    // }
    
  },
  computed: {
    selfCareScore: {
      get() { return this.$store.state.home_older.selfCareScore },
      set(val) { this.$store.commit('home_older/updateSelfCareScore', val) }
    },
    
    
    totalScore() {
     this.selfCareScore = this.selfCareScoreQuestions.map(question => question.selectedScore);
      // console.log("sbbb")
      // console.log(this.selfCareScore)
      return this.selfCareScoreQuestions.reduce((sum, question) => sum + question.selectedScore, 0);
    },
  },

  methods: {
    ifchecked(prop, prop1) {
      
      if(prop === prop1){
        console.log("tyyyssb")
        console.log(prop, prop1)
        return true;
      } 
      return false;
      }


  },

  mounted() {
    console.log(this.selfCareScoreQuestions);
    this.selfCareScoreQuestions.forEach(question => {
      console.log(question)
      this.$set(question, 'selectedScore', question.selectedScore);
    });
  }
};

</script>

<style scoped>
.question-section {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 11px;
}
.question-section h3{
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.option {
  margin-left: 10px;

}

.total-score {
  margin-top: 10px;
  font-weight: bold;
  font-size: 15px;

}
</style>
